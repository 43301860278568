<template>
  <div class="w-full flex space-x-4 mb-1 items-center">
    <div class="text-sm font-bold">
      <span :class="{ 'opacity-0': value.fullName === ' ' }">{{
        value.fullName === ' ' ? 'N/A' : value.fullName
      }}</span>
    </div>
    <div
      v-if="
        controls &&
          permission &&
          can(
            permission.module,
            permission.subModule,
            permission.childModule,
            permission.operation,
            permission.options
          )
      "
      class="flex space-x-2"
    >
      <router-link v-if="linkTo" :to="linkTo" class="text-base">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
          />
        </svg>
      </router-link>
      <a href="#" v-else @click.prevent="$emit('click')" class="text-base">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
          />
        </svg>
      </a>
      <a
        href="#"
        @click.prevent="
          $bus.$emit('show-modal', {
            show: action.show,
            title: action.title,
            component: action.component,
            payload: action.payload,
            message: action.message,
            resource: action.resource,
            redirect: action.redirect,
            event: action.event,
            maxWidth: action.maxWidth,
            callback: action.callback,
            type: action.type,
          })
        "
        class="text-base"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'form-edit',
  props: ['value', 'linkTo', 'controls', 'action', 'permission'],
  components: {},
  data() {
    return {}
  },
  methods: {},
}
</script>

<style></style>
