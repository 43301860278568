var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex space-x-4 mb-1 items-center"},[_c('div',{staticClass:"text-sm font-bold"},[_c('span',{class:{ 'opacity-0': _vm.value.fullName === ' ' }},[_vm._v(_vm._s(_vm.value.fullName === ' ' ? 'N/A' : _vm.value.fullName))])]),(
      _vm.controls &&
        _vm.permission &&
        _vm.can(
          _vm.permission.module,
          _vm.permission.subModule,
          _vm.permission.childModule,
          _vm.permission.operation,
          _vm.permission.options
        )
    )?_c('div',{staticClass:"flex space-x-2"},[(_vm.linkTo)?_c('router-link',{staticClass:"text-base",attrs:{"to":_vm.linkTo}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"d":"M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"}})])]):_c('a',{staticClass:"text-base",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('click')}}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"d":"M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"}})])]),_c('a',{staticClass:"text-base",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$bus.$emit('show-modal', {
          show: _vm.action.show,
          title: _vm.action.title,
          component: _vm.action.component,
          payload: _vm.action.payload,
          message: _vm.action.message,
          resource: _vm.action.resource,
          redirect: _vm.action.redirect,
          event: _vm.action.event,
          maxWidth: _vm.action.maxWidth,
          callback: _vm.action.callback,
          type: _vm.action.type,
        })}}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z","clip-rule":"evenodd"}})])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }